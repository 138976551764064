/* eslint-disable */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import intl from 'react-intl-universal';
import { useDispatch, useSelector, useStore } from 'react-redux';
import LoadingAnimation from '@components/common/loadingAnimation';
import * as LocalStore from 'store';

import { initializeApp } from 'firebase/app';
import GuestRoute from './hocs/GuestRoute';
import PrivateRoute from './hocs/PrivateRoute';
import routes from './routes';

import LocaleEN from './lang/en.json';
import LocaleTR from './lang/tr.json';
import LocaleNL from './lang/nl.json';
import LocaleES from './lang/es.json';
import LocaleDE from './lang/de.json';
import LocalePT from './lang/pt.json';
import LocaleIT from './lang/it.json';
import LocaleFR from './lang/fr.json';
import LocaleRU from './lang/ru.json';
import LocalePL from './lang/pl.json';
import LocaleID from './lang/id.json';
import LocaleSV from './lang/sv.json';
import LocaleBG from './lang/bg.json';
import LocaleRO from './lang/ro.json';
import LocaleAR from './lang/ar.json';
import LocaleCH from './lang/zh_Hans_CN.json';

import { ReactComponent as NewVersion } from './assets/images/new_plan.svg';
import setLocale from './actions/setLocale';
import setRbacDetails from './actions/setRbacDetails';
import { apiGet } from '@utils/api';

const locales = {
  'en-US': LocaleEN,
  'tr-TR': LocaleTR,
  'es-ES': LocaleES,
  'de-DE': LocaleDE,
  'pt-PT': LocalePT,
  'it-IT': LocaleIT,
  'fr-FR': LocaleFR,
  'ru-RU': LocaleRU,
  'pl-PL': LocalePL,
  'zh-CN': LocaleCH,
  'id-ID': LocaleID,
  'sv-SV': LocaleSV,
  'nl-NL': LocaleNL,
  'bg-BG': LocaleBG,
  'ro-RO': LocaleRO,
  'ar-AR': LocaleAR,
};

const App = () => {
  const currentLocale = useSelector((state) => state.locale);
  const serviceWorker = useSelector((state) => state.serviceWork);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const store = useStore();
  useLayoutEffect(() => {
    const getRbac = async () => {
      const rbacDetails = await apiGet({ url: '/rbac/v2/user/detail/' });
      dispatch(setRbacDetails(rbacDetails));
    };
    if (store.getState().auth?.user) getRbac();
  }, []);

  useEffect(() => {
    /* Locale global state default degeri env uzerinden alınıyordu.
    Artik null olarak initial değer aliyor. Baslangicta degeri yoksa browser lang uzerinden araniyor.
    Eger browser lang listemizde varsa onu yapiyoruz. Yoksa default deger en olacak sekilde set ediliyor. */
    setloading(true);
    let initialLocale = null;
    if (!currentLocale) {
      const listOfLanguages = ['tr-TR', 'en-US'];
      const browserLang = window.navigator.language.split('-')[0];
      listOfLanguages.map((lang) => {
        if (lang.includes(browserLang)) {
          dispatch(setLocale(lang));
          LocalStore.set('locale ', lang);
          initialLocale = lang;
        }
      });
      if (!initialLocale) {
        dispatch(setLocale('en-US'));
        initialLocale = 'en-US';
      }
      intl.init({ currentLocale: initialLocale, locales }).then(() => {
        setloading(false);
      });
    } else {
      initialLocale = currentLocale;
      intl.init({ currentLocale, locales }).then(() => {
        setloading(false);
      });
    }
  }, [currentLocale]);
  useEffect(() => {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_apiKey,
      authDomain: process.env.REACT_APP_FIREBASE_authDomain,
      databaseURL: process.env.REACT_APP_FIREBASE_databaseURL,
      projectId: process.env.REACT_APP_FIREBASE_projectId,
      storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
      messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId,
      appId: process.env.REACT_APP_FIREBASE_appId,
      measurementId: 'G-65CLJFEKZG',
    };
    try {
      initializeApp(firebaseConfig);
    } catch (error) {}
  }, []);

  return (
    <Router>
      <React.Suspense loading={loading} fallback={<LoadingAnimation />}>
        {serviceWorker.waitingServiceWorker && (
          <div className="service-worker-update">
            <div className="bold text-20">{intl.get('newVersion')}</div>
            <NewVersion />
            <div className="text-mid-gray fw-500">{intl.get('newVersionDesc_temp')}</div>
          </div>
        )}
        <Switch>
          {routes.map(
            (route) =>
              route.component &&
              (route.isPrivate ? (
                <PrivateRoute
                  key={route.path}
                  permissionID={route.permissionID}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ) : (
                <GuestRoute key={route.path} path={route.path} exact={route.exact} component={route.component} />
              )),
          )}
          <Route render={() => <h1>Not found!</h1>} />
        </Switch>
      </React.Suspense>
    </Router>
  );
};

export default App;
