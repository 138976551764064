/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle  */
import axios from 'axios';
import store from 'store';
import { store as reduxStore } from '../store';
import { decryptResponseData, encryptPayloadData } from './helpers';

export const getAuth = () => store.get('_aut');
export const setAuth = (auth) => store.set('_aut', auth);
export const getCompany = () => store.get('company');

const defaultOptions = (isFile, useOptiyolCompany = true) => {
  const headers = {
    'Content-Type': isFile ? 'multipart/form-data' : 'application/json',
  };
  const authorization = getAuth();
  if (authorization) {
    headers.Authorization = `Token ${authorization.token}`;
  }
  const company = reduxStore.getState().company.companyID || {};
  if (company && company.name && useOptiyolCompany) {
    headers['Optiyol-Company'] = company.name;
  }
  const locale = reduxStore.getState().locale || {};
  if (locale) {
    headers['accept-language'] = locale;
  }

  return { headers };
};
const defaultInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const defaultBaseInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL_BASE,
});

const routeInstance = axios.create({
  baseURL: process.env.REACT_APP_ROUTE_API_URL,
});

const reportingInstance = axios.create({
  baseURL: process?.env.REACT_APP_REPORTING_API_URL,
});
const eventsInstance = axios.create({
  baseURL: process?.env.REACT_APP_EVENTS_API_URL,
});

export const localInstance = axios.create();

export const apiGet = async ({
  url,
  options = {},
  cancelToken,
  onCancelled = () => {},
  onError,
  aes256GcmEncrypted = false,
  returnAllErrorObjectAsJson,
}) => {
  try {
    const results = await defaultInstance.get(url, {
      headers: {
        ...defaultOptions(false).headers,
        ...options.headers,
      },
      params: {
        ...options.params,
      },
      cancelToken,
    });
    if (aes256GcmEncrypted && results.data && results.ciphertext && results.nonce && results.tag) {
      return decryptResponseData(results.data.ciphertext, results.data.nonce, results.data.tag);
    }
    if (results.data) return results.data;
    return false;
  } catch (ex) {
    if (onError) throw ex.response;
    if (axios.isCancel(ex)) onCancelled();
    if (returnAllErrorObjectAsJson) throw ex.toJSON();
    else console.error(ex);
    // @todo do something smart here cucu
    return false;
  }
};

export const apiDownloadFile = async ({ url, options = {}, aes256GcmEncrypted = false }) => {
  try {
    const results = await defaultInstance.get(url, {
      headers: {
        ...defaultOptions(false).headers,
        ...options.headers,
      },
      params: {
        ...options.params,
      },
      responseType: 'blob',
    });
    if (aes256GcmEncrypted && results && results.ciphertext && results.nonce && results.tag) {
      return decryptResponseData(results.data.ciphertext, results.data.nonce, results.data.tag);
    }
    if (results.data) return results.data;
    return false;
  } catch (ex) {
    console.error(ex);
    // @todo do something smart here cucu
    return false;
  }
};

export const apiDownloadFilePost = async ({ url, data, options = {}, aes256GcmEncrypted = false, fullError = false }) => {
  try {
    const results = await defaultInstance.post(url, data, {
      headers: {
        ...defaultOptions(false).headers,
        ...options.headers,
      },
      params: {
        ...options.params,
      },
      responseType: 'blob',
    });
    if (aes256GcmEncrypted && results.data && results.ciphertext && results.nonce && results.tag) {
      return decryptResponseData(results.data.ciphertext, results.data.nonce, results.data.tag);
    }
    if (results.data) return results.data;
    return false;
  } catch (ex) {
    if (fullError) {
      const errorBlob = await ex.response.data.text();
      return {
        success: false,
        ...JSON.parse(errorBlob),
      };
    }
    return false;
  }
};

export const apiPost = async ({
  url,
  data,
  options = { useOptiyolCompany: true },
  onError = false,
  fullError = false,
  throwErrorWithJson = false,
  aes256GcmEncrypted = false,
}) => {
  try {
    const results = await defaultInstance.post(url, data, {
      ...defaultOptions(false, options.useOptiyolCompany),
      ...options,
    });
    if (aes256GcmEncrypted && results.data && results.ciphertext && results.nonce && results.tag) {
      return decryptResponseData(results.data.ciphertext, results.data.nonce, results.data.tag);
    }
    if (results.data) return results.data;
    return results;
  } catch (ex) {
    if (fullError) {
      return ex.response;
    }
    if (onError) {
      return ex.response?.data;
    }
    if (throwErrorWithJson) throw JSON.stringify(ex.response);
    return false;
  }
};

export const apiBasePost = async ({
  url,
  data,
  options = { useOptiyolCompany: true },
  onError = false,
  fullError = false,
  throwErrorWithJson = false,
  aes256GcmEncrypted = false,
}) => {
  try {
    const results = await defaultBaseInstance.post(url, data, {
      ...defaultOptions(false, options.useOptiyolCompany),
      ...options,
    });
    if (aes256GcmEncrypted && results.data && results.ciphertext && results.nonce && results.tag) {
      return decryptResponseData(results.data.ciphertext, results.data.nonce, results.data.tag);
    }
    if (results.data) return results.data;
    return results;
  } catch (ex) {
    if (fullError) {
      return ex.response;
    }
    if (onError) {
      return ex.response?.data;
    }
    if (throwErrorWithJson) throw JSON.stringify(ex.response);
    return false;
  }
};

export const apiPatch = async ({ url, data, options = {}, onError = false, throwErrorWithJson = false, aes256GcmEncrypted = false }) => {
  try {
    const results = await defaultInstance.patch(url, data, {
      ...defaultOptions(false),
      ...options,
    });
    if (aes256GcmEncrypted && results.data && results.ciphertext && results.nonce && results.tag) {
      return decryptResponseData(results.data.ciphertext, results.data.nonce, results.data.tag);
    }
    if (results.data) return results.data;
    return results;
  } catch (ex) {
    if (onError) {
      return ex.response?.data;
    }
    if (throwErrorWithJson) throw JSON.stringify(ex.response);
    return false;
  }
};

export const apiPostProgress = async ({ url, data, onUploadProgress, options = {}, aes256GcmEncrypted = false }) => {
  try {
    const results = await defaultInstance.post(url, data, {
      ...defaultOptions(false),
      ...options,
      onUploadProgress,
    });
    if (aes256GcmEncrypted && results.data && results.ciphertext && results.nonce && results.tag) {
      return decryptResponseData(results.data.ciphertext, results.data.nonce, results.data.tag);
    }
    if (results.data) return results.data;
    return false;
  } catch (ex) {
    console.error(ex);
    // @todo do something smart here cucu
    return false;
  }
};

export const apiPut = async ({ url, data, options = {}, onError = false, throwErrorWithJson = false, aes256GcmEncrypted = false }) => {
  try {
    const results = await defaultInstance.put(url, data, {
      ...defaultOptions(false),
      ...options,
    });
    if (aes256GcmEncrypted && results.data && results.ciphertext && results.nonce && results.tag) {
      return decryptResponseData(results.data.ciphertext, results.data.nonce, results.data.tag);
    }
    if (results.data) return results.data;
    return false;
  } catch (ex) {
    if (onError) {
      return ex.response?.data;
    }
    if (throwErrorWithJson) throw JSON.stringify(ex.response);
    return false;
  }
};

export const apiDelete = async ({ url, data, options = {}, onError = false, aes256GcmEncrypted = false }) => {
  try {
    const results = await defaultInstance.delete(url, {
      data,
      ...defaultOptions(false),
      ...options,
    });
    if (aes256GcmEncrypted && results.data && results.data.ciphertext && results.data.nonce && results.data.tag) {
      return decryptResponseData(results.data.ciphertext, results.data.nonce, results.data.tag);
    }
    if (results.data) return results.data;

    if (results.status < 300) return true;
    return false;
  } catch (ex) {
    if (onError) {
      if (aes256GcmEncrypted && ex.response?.data && ex.response?.data.ciphertext && ex.response?.data.nonce && ex.response?.data.tag) {
        return decryptResponseData(ex.response?.data.ciphertext, ex.response?.data.nonce, ex.response?.data.tag);
      }
      return ex.response?.data;
    }
    console.error(ex);
    // @todo do something smart here cucu
    return false;
  }
};

export const routeApi = () => ({
  get: async ({ url, options = {}, onError = false, aes256GcmEncrypted = false }) => {
    try {
      const results = await routeInstance.get(url, {
        ...defaultOptions(false, options.useOptiyolCompany),
        ...options,
      });
      if (aes256GcmEncrypted && results.data && results.data.ciphertext && results.data.nonce && results.data.tag) {
        return decryptResponseData(results.data.ciphertext, results.data.nonce, results.data.tag);
      }
      if (results.data) return results.data;
      return false;
    } catch (ex) {
      if (onError) {
        if (aes256GcmEncrypted && ex.response?.data && ex.response?.data.ciphertext && ex.response?.data.nonce && ex.response?.data.tag) {
          return decryptResponseData(ex.response?.data.ciphertext, ex.response?.data.nonce, ex.response?.data.tag);
        }
        return ex.response?.data;
      }
      return false;
    }
  },
  delete: async ({ url, data, options = {}, returnType = 'data', onError = false, aes256GcmEncrypted = false }) => {
    try {
      const results = await routeInstance.delete(url, {
        data,
        ...defaultOptions(false),
        ...options,
      });
      if (aes256GcmEncrypted && results.data && results.data.ciphertext && results.data.nonce && results.data.tag) {
        return decryptResponseData(results.data.ciphertext, results.data.nonce, results.data.tag);
      }
      if (results.data && returnType === 'data') return results.data;
      if (returnType === 'status') return results.status;
      return false;
    } catch (ex) {
      if (onError) {
        if (aes256GcmEncrypted && ex.response?.data && ex.response?.data.ciphertext && ex.response?.data.nonce && ex.response?.data.tag) {
          return decryptResponseData(ex.response?.data.ciphertext, ex.response?.data.nonce, ex.response?.data.tag);
        }
        return ex.response?.data;
      }
    }
  },
  post: async ({ url, data, options = {}, onError = false, onFullError = false, aes256GcmEncrypted = false }) => {
    try {
      const results = await routeInstance.post(url, data, { ...defaultOptions(false), ...options });
      if (aes256GcmEncrypted && results.data && results.data.ciphertext && results.data.nonce && results.data.tag) {
        return decryptResponseData(results.data.ciphertext, results.data.nonce, results.data.tag);
      }
      if (results.data) return results.data;
      return false;
    } catch (ex) {
      if (onError) {
        if (aes256GcmEncrypted && ex.response?.data && ex.response?.data.ciphertext && ex.response?.data.nonce && ex.response?.data.tag) {
          return decryptResponseData(ex.response?.data.ciphertext, ex.response?.data.nonce, ex.response?.data.tag);
        }
        return ex.response?.data;
      }
      if (onFullError) {
        return ex.response;
      }
      return false;
    }
  },
  put: async ({ url, data, options = {}, onError = false, onFullError = false, aes256GcmEncrypted = false }) => {
    try {
      const results = await routeInstance.put(url, data, { ...defaultOptions(false), ...options });
      if (aes256GcmEncrypted && results.data && results.data.ciphertext && results.data.nonce && results.data.tag) {
        return decryptResponseData(results.data.ciphertext, results.data.nonce, results.data.tag);
      }
      if (results.data) return results.data;
      return false;
    } catch (ex) {
      if (onError) {
        return ex.response?.data;
      }
      if (onFullError) {
        return ex.response;
      }
    }
  },
  downloadFile: async ({ url, data, options = {}, aes256GcmEncrypted = false, onError = false, encryptPayload = false }) => {
    try {
      const payload = encryptPayload ? encryptPayloadData(data) : data;
      const results = await routeInstance.post(url, payload, {
        headers: {
          ...defaultOptions(false).headers,
          ...options.headers,
        },
        params: {
          ...options.params,
        },
        responseType: 'blob',
      });
      if (aes256GcmEncrypted && results.data && results.data.ciphertext && results.data.nonce && results.data.tag) {
        return decryptResponseData(results.data.ciphertext, results.data.nonce, results.data.tag);
      }
      if (results.data) return results.data;
      return false;
    } catch (error) {
      if (onError) {
        const errorBlob = await error.response.data.text();
        return JSON.parse(errorBlob);
      }
      return false;
    }
  },
  downloadFileGet: async ({ url, options = {}, aes256GcmEncrypted = false }) => {
    try {
      const results = await routeInstance.get(url, {
        headers: {
          ...defaultOptions(false).headers,
          ...options.headers,
        },
        params: {
          ...options.params,
        },
        responseType: 'blob',
      });
      if (aes256GcmEncrypted && results.data && results.data.ciphertext && results.data.nonce && results.data.tag) {
        return decryptResponseData(results.data.ciphertext, results.data.nonce, results.data.tag);
      }
      if (results.data) return results.data;
      return false;
    } catch (error) {
      return false;
    }
  },
  postFile: ({ url, data, options = {} }) => routeInstance.post(url, data, { ...defaultOptions(true), ...options }),

  patch: async ({ url, data, options = {}, onError, aes256GcmEncrypted = false }) => {
    try {
      const result = await routeInstance.patch(url, data, { ...defaultOptions(false), ...options });
      if (aes256GcmEncrypted && result.data && result.data.ciphertext && result.data.nonce && result.data.tag) {
        return decryptResponseData(result.data.ciphertext, result.data.nonce, result.data.tag);
      }
      if (result.data) return result.data;
    } catch (ex) {
      if (onError) {
        if (onError) {
          if (aes256GcmEncrypted && ex.response?.data && ex.response?.data.ciphertext && ex.response?.data.nonce && ex.response?.data.tag) {
            return decryptResponseData(ex.response?.data.ciphertext, ex.response?.data.nonce, ex.response?.data.tag);
          }
          return ex.response?.data;
        }
      }
      return false;
    }
  },
});

export const reportApi = () => ({
  get: async ({ url, options = {}, aes256GcmEncrypted = false }) => {
    try {
      const results = await reportingInstance.get(url, {
        ...defaultOptions(false),
        ...options,
      });
      if (aes256GcmEncrypted && results.data && results.data.ciphertext && results.data.nonce && results.data.tag) {
        return decryptResponseData(results.data.ciphertext, results.data.nonce, results.data.tag);
      }
      if (results.data) return results.data;
      return false;
    } catch (error) {
      return false;
    }
  },
  downloadFile: async ({ url, data, options = {}, aes256GcmEncrypted = false, onError = false }) => {
    try {
      const results = await reportingInstance.get(url, {
        headers: {
          ...defaultOptions(false, options.useOptiyolCompany).headers,
          ...options.headers,
        },
        params: {
          ...options.params,
        },
        responseType: 'blob',
      });
      if (aes256GcmEncrypted && results.data && results.data.ciphertext && results.data.nonce && results.data.tag) {
        return decryptResponseData(results.data.ciphertext, results.data.nonce, results.data.tag);
      }
      if (results.data) return results.data;
      return false;
    } catch (error) {
      if (onError) {
        const errorBlob = await error.response.data.text();
        return {
          success: false,
          ...JSON.parse(errorBlob),
        };
      }
      return false;
    }
  },
  downloadFilePost: async ({ url, data, options = {}, aes256GcmEncrypted = false }) => {
    try {
      const results = await reportingInstance.post(url, data, {
        headers: {
          ...defaultOptions(false, options.useOptiyolCompany).headers,
          ...options.headers,
        },
        params: {
          ...options.params,
        },
        responseType: 'blob',
      });
      if (aes256GcmEncrypted && results.data && results.ciphertext && results.nonce && results.tag) {
        return decryptResponseData(results.data.ciphertext, results.data.nonce, results.data.tag);
      }

      if (results.data) return results.data;
      return false;
    } catch (error) {
      return false;
    }
  },
  delete: async ({ url, data, options = {}, returnType = 'data', aes256GcmEncrypted = false }) => {
    const results = await reportingInstance.delete(url, data, {
      ...defaultOptions(false),
      ...options,
    });
    if (results.data && returnType === 'data') return results.data;
    if (returnType === 'status') return results.status;
    return false;
  },
  post: async ({ url, data, options = {}, aes256GcmEncrypted = false }) => {
    const results = await reportingInstance.post(url, data, {
      ...defaultOptions(false),
      ...options,
    });
    if (aes256GcmEncrypted && results.data && results.ciphertext && results.nonce && results.tag) {
      return decryptResponseData(results.data.ciphertext, results.data.nonce, results.data.tag);
    }
    if (results.data) return results.data;
    return false;
  },
  put: async ({ url, data, options = {}, aes256GcmEncrypted = false }) => {
    const results = await reportingInstance.put(url, data, {
      ...defaultOptions(false),
      ...options,
    });
    if (aes256GcmEncrypted && results.data && results.ciphertext && results.nonce && results.tag) {
      return decryptResponseData(results.data.ciphertext, results.data.nonce, results.data.tag);
    }
    if (results.data) return results.data;
    return false;
  },
  postFile: ({ url, data, options = {} }) => reportingInstance.post(url, data, { ...defaultOptions(true), ...options }),
  patch: async ({ url, options = {}, aes256GcmEncrypted = false }) => {
    const result = await reportingInstance.patch(url, { ...defaultOptions(false), ...options });
    if (result.data) return result.data;
    return false;
  },
});

export const localApi = () => ({
  get: (url, options = {}) => localInstance.get(url, { ...defaultOptions(false), ...options }),
  getFile: (url, options = {}) =>
    localInstance.get(url, {
      ...defaultOptions(false),
      responseType: 'arraybuffer',
      ...options,
    }),
  post: (url, data, options = {}) => localInstance.post(url, data, { ...defaultOptions(false), ...options }),
  postFile: (url, data, options = {}) => localInstance.post(url, data, { ...defaultOptions(true), ...options }),
});

export const eventsApi = () => ({
  get: async ({ url, options = {}, aes256GcmEncrypted = false }) => {
    try {
      const results = await eventsInstance.get(url, {
        ...defaultOptions(false),
        ...options,
      });
      if (aes256GcmEncrypted && results.data && results.data.ciphertext && results.data.nonce && results.data.tag) {
        return decryptResponseData(results.data.ciphertext, results.data.nonce, results.data.tag);
      }
      if (results.data) return results.data;
      return false;
    } catch (error) {
      return false;
    }
  },
});
