/* eslint-disable */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/style.scss';
import { persistor, store } from './store';
import { loadScripts } from '@utils/helpers';
import packageJson from '../package.json';
import setServiceWorkerUpdated from './actions/setServiceWorker';
import * as serviceWorker from './serviceWorker';
import { useHistory } from 'react-router-dom';

const Index = () => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const handleError = async (err) => {
    console.error(err);
    if (!navigator.serviceWorker) {
      return;
    }
    if (process?.env.NODE_ENV !== 'development') {
      const registration = await navigator.serviceWorker.ready;
      if (registration.installing || registration.waiting) {
        navigator.serviceWorker.ready.then(async (registration) => {
          await registration.unregister();
          window.location.reload();
        });
      }
    }
  };
  useEffect(() => {
    window.addEventListener('error', handleError);
    if (window.H) setLoading(false);
    if (!window.H && !window.location.href.includes('here_access')) {
      setLoading(true);
      loadScripts(
        [
          'https://js.api.here.com/v3/3.1/mapsjs-core.js',
          'https://js.api.here.com/v3/3.1/mapsjs-service.js',
          'https://js.api.here.com/v3/3.1/mapsjs-ui.js',
          'https://js.api.here.com/v3/3.1/mapsjs-mapevents.js',
          'https://js.api.here.com/v3/3.1/mapsjs-data.js',
          'https://js.api.here.com/v3/3.1/mapsjs-harp.js',
        ],
        (callback) => {
          if (!callback) {
            window.location.href = `${window.location.origin}/here_access`;
          } else {
            setLoading(false);
          }
        },
      );
    } else if (window.location.href.includes('here_access')) {
      setLoading(false);
      console.log('Here map service provider access problem');
    }
    return () => {
      window.removeEventListener('error', handleError);
    };
  }, [history]);
  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {loading ? <div /> : <App />}
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
};
if (process?.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://46d3ff08cdb343bc8f1707a60b5ee310@o547742.ingest.sentry.io/6538857',
    release: `optiyolwebapp@${packageJson.version}`,
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications.'],
    environment: process?.env.NODE_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(<Index />, document.getElementById('root'));

reportWebVitals();

const configuration = {
  onUpdate: (reg) => {
    if (reg && reg.waiting) {
      store.dispatch(
        setServiceWorkerUpdated({
          waitingServiceWorker: true,
        }),
      );
      setTimeout(() => {
        store.dispatch(
          setServiceWorkerUpdated({
            waitingServiceWorker: false,
          }),
        );
        /* window.location.reload(); */
      }, 3000);
    }
  },
};
serviceWorker.register(configuration);
