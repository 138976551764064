import {
  SET_FILTERS_ORDER_AND_VISIBILITY,
  SET_PLANNING_LIST_LAST_FILTER,
  SET_SAVED_FILTERS,
  SET_SHIPPING_LIST_EXPORT_OPTIONS,
  SET_PAGE_SIZE,
} from '../actions/types';

const INITIAL_STATE = {};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SAVED_FILTERS: {
      const { filterGroupName, values } = action.payload;
      const temp = state[filterGroupName] ?? [];
      temp.unshift(values);
      if (temp.length > 5) temp.pop();
      return { ...state, [filterGroupName]: temp };
    }
    case SET_FILTERS_ORDER_AND_VISIBILITY: {
      const { filterGroupName, visibleFilters } = action.payload;
      return { ...state, visibility: { ...state.visibility, [filterGroupName]: visibleFilters } };
    }
    case SET_SHIPPING_LIST_EXPORT_OPTIONS: {
      return { ...state, shippingListExportOptions: action.payload };
    }
    case SET_PLANNING_LIST_LAST_FILTER: {
      return { ...state, planningListLastFilter: action.payload };
    }
    case SET_PAGE_SIZE: {
      const { tableName, data } = action.payload;
      return { ...state, pageSize: { ...state.pageSize, [tableName]: data } };
    }
    default:
      return state;
  }
};
